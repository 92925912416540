import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import InventoryIcon from "@mui/icons-material/Inventory";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import MarkunreadMailboxIcon from "@mui/icons-material/MarkunreadMailbox";
import PaidIcon from "@mui/icons-material/Paid";
import PendingIcon from "@mui/icons-material/Pending";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import {
  Alert,
  Button,
  InputLabel,
  Step,
  StepLabel,
  Stepper,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import React, { useCallback, useEffect, useState } from "react";
import { POSTI_TRACKING_URL } from "../../config";
import { useApiErrorContext } from "../../contexts/ApiErrorContext";
import { useUserContext } from "../../contexts/UserContext";
import { useTransactionService } from "../../hooks/useTransactionService";
import { useValidation } from "../../hooks/useValidation";
import Transaction from "../../shared/model/transaction";
import { imageNameMatcher } from "../../util/images";
import { formatDate, formatPrice, getActionName } from "../../util/transaction";
import calculateFee from "../create-transaction/FeeCalculator";
import InputTextField from "./form/InputTextField";
import ManageProductImages from "./ManageProductImages";

function TransactionInformation({ name, description, user_name, receiver_name, receiver_email, role, user_role, received_date, deliver_date, price, fee, currency, id, posti_tracking_id, payment_method }) {
  const receiver_role =
    user_role === Transaction.UserRole.Seller ?
      Transaction.UserRole.Buyer :
      Transaction.UserRole.Seller;
  let items;
  if (payment_method !== "ennakko") {
    items = [
      //{ label: "Rooli", value: role },
      { label: "Turvamaksu (" + user_role + ")", value: user_name },
      { label: "Vastaanottaja (" + receiver_name + " " + receiver_role + ")", value: receiver_email },
      { label: "Tuotteen nimi", value: name },
      { label: "Kauppasumma", value: formatPrice(price, currency) },
      { label: "turvamaksut.fi veloitus", value: formatPrice(fee, currency) },
      { label: "Tuotteen kuvaus", value: description },
      { label: "Lähetetty", value: formatDate(deliver_date) },
      { label: "Vastaanotettu", value: formatDate(received_date) },
      { label: "Tapahtumatunnus", value: id },
      {
        label: "Lähetystunnus",
        value: posti_tracking_id ?
          <a href={`${POSTI_TRACKING_URL}/${posti_tracking_id}`} target="_blank" rel="noopener noreferrer">{posti_tracking_id}</a> : null,
      },
    ];
  }
  else {
    items = [
      { label: "Ennakkomaksu (" + user_role + ")", value: user_name },
      { label: "Vastaanottaja (" + receiver_role + ")", value: receiver_email },
      { label: "Tuotteen nimi", value: name },
      { label: "Kauppasumma", value: formatPrice(price, currency) },
      { label: "turvamaksut.fi veloitus", value: formatPrice(fee, currency) },
      { label: "Tuotteen kuvaus", value: description },
      { label: "Lähetetty", value: formatDate(deliver_date) },
      { label: "Vastaanotettu", value: formatDate(received_date) },
      { label: "Tapahtumatunnus", value: id },
    ];
  }

  return (
    <Grid item container direction="column" spacing={2}>
      {items
        .filter(({ label, value }) => !!value)
        .map(({ label, value }) => (
          <Grid item key={label}>
            <InputLabel>{label}</InputLabel>
            <Typography variant="body2" whiteSpace="pre-wrap">{value}</Typography>
          </Grid>
        ))}
    </Grid>
  );
}

function ProductStatus({ status, payment_method }) {
  let steps;
  if (payment_method === "ennakko") {
    steps = [
      { status: Transaction.Status.Registration, label: "Odottaa myyjän rekisteröitymistä", Icon: HourglassEmptyIcon },
      { status: Transaction.Status.AwaitingPayment, label: "1. Odottaa maksua", Icon: WatchLaterIcon },
      { status: Transaction.Status.AwaitingPaymentConfirmation, label: "2. Maksu odottaa pankin vahvistusta", Icon: PendingIcon },
      { status: Transaction.Status.Paid, label: "3. Maksettu", Icon: PaidIcon },
      { status: Transaction.Status.Received, label: "4. Tuote merkitty vastaanotetuksi", Icon: CheckIcon },
    ];
  } else {
    steps = [
      { status: Transaction.Status.Registration, label: "Odottaa myyjän rekisteröitymistä", Icon: HourglassEmptyIcon },
      { status: Transaction.Status.AwaitingPayment, label: "1. Odottaa maksua", Icon: WatchLaterIcon },
      { status: Transaction.Status.AwaitingPaymentConfirmation, label: "2. Maksu odottaa pankin vahvistusta", Icon: PendingIcon },
      { status: Transaction.Status.Paid, label: "3. Maksettu", Icon: PaidIcon },
      { status: Transaction.Status.Delivered, label: "4. Lähetys on matkalla", Icon: LocalShippingIcon },
      { status: Transaction.Status.ReadyForPickup, label: "5. Lähetys on noudettavissa", Icon: MarkunreadMailboxIcon },
      { status: Transaction.Status.PickedUp, label: "6. Ostaja on noutanut lähetyksen", Icon: InventoryIcon },
      { status: Transaction.Status.Received, label: "7. Ostaja on hyväksynyt tuotteen ja maksu on tilitetty", Icon: CheckIcon },
    ];
  }
  const activeStep = steps.findIndex(step => step.status === status);

  return (
    <Stepper activeStep={activeStep} orientation="vertical">
      {steps.map(({ label, Icon }, index) => {
        const completed = activeStep >= index;
        const iconProps = {
          color: completed ? "inherit" : "disabled",
        };
        return (
          <Step key={label} completed={!!completed}>
            <StepLabel
              StepIconComponent={Icon}
              StepIconProps={iconProps}
              sx={{ fontWeight: activeStep === index ? "bold" : "regular", padding: "3px" }}
            >
              {label}
            </StepLabel>
          </Step>
        );
      })}
    </Stepper>
  );
}

function ProductStatusCardContent({
  handleClose,
  handleActionButton,
  transaction,
  images,
  loading,
  onEdit,
  canEdit,
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const action = getActionName(transaction.status, transaction.role, transaction.receiver_user_id);

  return (
    <>
      {images.length > 0 && (
        <ManageProductImages
          images={images}
          editable={false}
          sx={{ width: "100%", backgroundColor: "transparent" }}
        />
      )}
      <Grid
        container
        spacing={4}
        padding={isMobile ? 2 : 4}
        alignItems="flex-start"
        justifyContent="space-between"
        direction="column"
      >
        <IconButton
          color="blackButton"
          onClick={handleClose}
          sx={{
            position: "absolute", top: 10, right: 10
          }}
        >
          <CloseIcon />
        </IconButton>
        <Grid item>
          <Typography variant="h5">
            {transaction.name}
          </Typography>
        </Grid>
        <Grid item container direction={isMobile ? "column" : "row"} spacing={2} justifyContent="space-between" wrap="nowrap">
          <Grid item container direction="column" spacing={2}>
            <Grid item>
              <TransactionInformation {...transaction} />
            </Grid>
            {canEdit && (
              <Grid item>
                <Button onClick={onEdit}>
                  <EditIcon fontSize="small" sx={{ mr: 1 }} />
                  Muokkaa
                </Button>
              </Grid>
            )}
          </Grid>
          <Grid item container direction="column" spacing={2}>
            <Grid item>
              <InputLabel>Tila</InputLabel>
            </Grid>
            <Grid item>
              <ProductStatus status={transaction.status} payment_method={transaction.payment_method} />
            </Grid>
            <Grid item>
              {action && (
                action ?
                  (Array.isArray(action) && action.length > 1 ?
                    action.map((ac, index) => (
                      <Button key={index}
                        variant={index === 1 ? "text" : "contained"} fullWidth
                        color={index === 1 ? "error" : "success"}
                        size={index === 1 ? "small" : "default"}
                        onClick={(event) => handleActionButton(event, index)}
                        startIcon={index === 1 ?
                          <ErrorOutlineIcon color="error" />
                          : null}
                        disabled={loading}>
                        {!loading ? ac : <CircularProgress size={24} />}
                      </Button>
                    )) : (
                      <Button variant="contained" fullWidth onClick={handleActionButton}
                        disabled={loading}>
                        {!loading ? action : <CircularProgress size={24} />}
                      </Button>
                    )
                  ) : null
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

const labels = {
  name: "Tuotteen nimi",
  price: "Kauppasumma",
  fee: "turvamaksut.fi veloitus",
  email: "Vastaanottajan sähköpostiosoite",
  description: "Tuotteen kuvaus",
};

function EditProduct({ transaction, images, onCancel, onSubmit }) {
  const [modifiedImages, setModifiedImages] = useState(images);
  const [loading, setLoading] = useState(false);
  const apiErrorContext = useApiErrorContext();
  const transactionService = useTransactionService();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const validation = {
    name: useValidation(Transaction.validation.name),
    price: useValidation(Transaction.validation.price),
    fee: useValidation(Transaction.validation.fee),
    description: useValidation(Transaction.validation.description),
  };

  const handleFee = (event) => {
    const price = event.target.value;
    const fee = calculateFee(price);
    if (fee > 0) {
      document.getElementById("fee").value = formatPrice(fee, "");
    }
  };

  const submit = async (event) => {
    event.preventDefault();

    const errors = Object.entries(validation)
      .map(([key, validation]) => ([key, validation.validate()]))
      .filter(([key, error]) => error !== null);

    if (errors.length > 0) {
      const error = errors.map(([key, error]) => `- ${labels[key] ?? "N/A"}: ${error}`).join("\n");
      apiErrorContext.addError(`Tiedoissa on virheitä:\n\n${error}`);
      return;
    }

    try {
      setLoading(true);

      await transactionService.modify(transaction.id, {
        name: validation.name.inputRef.current.value,
        price: validation.price.inputRef.current.value,
        fee: formatPrice(calculateFee(validation.price.inputRef.current.value), ""),
        description: validation.description.inputRef.current.value,
        email: transaction.receiver_email,
      });

      const newImages = modifiedImages.filter(image => !!image.data_url);
      const removedImages = images.filter(
        image => !modifiedImages.some(modifiedImage => modifiedImage.url === image.url)
      );
      await Promise.all(newImages.map(
        image => transactionService.addImage(transaction.id, { img: image.data_url })
      ));
      await Promise.all(removedImages.map(
        image => transactionService.removeImage(
          transaction.id,
          image.url.match(imageNameMatcher)[1]
        )
      ));

      onSubmit();
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={submit} style={{ height: "fit-content" }}>
      <Grid
        container
        padding={isMobile ? 2 : 4}
        alignItems="center"
        justifyContent="space-between"
        direction="column"
      >
        <Grid item mb={2}>
          <Typography variant="h5">Muokkaa kaupan tietoja</Typography>
        </Grid>
        <Grid item mb={2}>
          <ManageProductImages images={modifiedImages} onImagesChange={setModifiedImages} />
        </Grid>
        <Grid item width="100%">
          <InputTextField
            label={labels.name}
            fullWidth
            autoFocus
            defaultValue={transaction.name}
            {...validation.name}
          />
        </Grid>
        <Grid item container justifyContent="space-between" width="100%">
          <Grid item>
            <InputTextField
              label={labels.price}
              fullWidth
              endAdornment="€"
              defaultValue={String(parseFloat(transaction.price))}
              onChange={handleFee}
              {...validation.price}
            />
          </Grid>
          <Grid item>
            <InputTextField
              disabled
              label={labels.fee}
              id="fee"
              defaultValue={String(formatPrice(transaction.fee, ""))}
              endAdornment="€"
            />
          </Grid>
        </Grid>
        <Grid item width="100%">
          <InputTextField
            label={labels.description}
            fullWidth
            multiline
            defaultValue={transaction.description}
            {...validation.description}
          />
        </Grid>
        <Grid item container width="100%" justifyContent="space-between">
          <Button variant="outlined" onClick={onCancel}>
            Peruuta
          </Button>
          <Button variant="contained" type="submit" sx={{ width: 100 }} disabled={loading}>
            {loading ? <CircularProgress size={24} color="inherit" /> : "Tallenna"}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}

export default function ProductStatusCard({ transactionId, handleClose, updateStatus, reload }) {
  const { user } = useUserContext();
  const transactionService = useTransactionService();

  const [error, setError] = useState(false);
  const [transaction, setTransaction] = useState({});
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editing, setEditing] = useState(false);

  const fetchData = useCallback(async () => {
    if (transactionId === null) {
      return;
    }

    try {
      setError(false);
      setLoading(true);
      setTransaction(await transactionService.getById(transactionId));
      setImages((await transactionService.getImages(transactionId)).map(url => ({ url })));
    } catch {
      setError(true);
    } finally {
      setLoading(false);
    }
  }, [transactionId, transactionService]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleActionButton = async (event, index = null) => {
    await updateStatus(transaction, index);
    await fetchData();
  };

  const canEdit =
    transaction.status === Transaction.Status.AwaitingPayment
    && transaction.user_id === user.id;

  return (
    <Box sx={{ width: { sx: "100vw", md: 700 } }}>
      {loading || transactionId === null ? (
        <Box display="flex" width="100%" height={700} alignItems="center" justifyContent="center">
          <CircularProgress color="primary" size={100} />
        </Box>
      ) : error ? (
        <Alert variant="filled" severity="error">
          Kaupan tietojen lataaminen epäonnistui
        </Alert>
      ) : editing ? (
        <EditProduct
          transaction={transaction}
          images={images}
          onCancel={() => setEditing(false)}
          onSubmit={() => {
            setEditing(false);
            fetchData();
            reload();
          }}
        />
      ) : (
        <ProductStatusCardContent
          handleClose={handleClose}
          transaction={transaction}
          images={images}
          handleActionButton={handleActionButton}
          loading={loading}
          canEdit={canEdit}
          onEdit={() => setEditing(true)}
        />
      )}
    </Box>
  );
}
