import AddSharpIcon from "@mui/icons-material/AddSharp";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useApiErrorContext } from "../../contexts/ApiErrorContext";
import { CreateTransactionContext as CreatePrepaymentContext } from "../../contexts/CreateTransactionContext";
import { useLoadingContext, withLoading } from "../../contexts/LoadingContext";
import { useValidation } from "../../hooks/useValidation";
import Prepayment from "../../shared/model/prepayment";
import { formatPrice } from "../../util/transaction";
import InputTextField from "../common/form/InputTextField";
import VerticalContentGrid from "../common/VerticalContentGrid";
import calculateFee from "./FeeCalculator";

const labels = {
  name: "Tuotteen nimi",
  price: "Kauppasumma",
  fee: "turvamaksut.fi veloitus",
  description: "Lisätiedot (kuvaile tuotetta tarkemmin, tiedot välitetään kaupan toiselle osapuolelle)",
  email: "Vastaanottaja (lisää tähän kohteen myyjän sähköposti)",
};

export default function PreppaymentAdditionalInformation() {
  const navigate = useNavigate();
  const loadingContext = useLoadingContext();
  const createPrepaymentContext = useContext(CreatePrepaymentContext);
  const prepaymentId = createPrepaymentContext.current.id;
  const apiErrorContext = useApiErrorContext();

  const validation = {
    name: useValidation(Prepayment.validation.name),
    price: useValidation(Prepayment.validation.price),
    fee: useValidation(Prepayment.validation.fee),
    payment_method: useValidation(Prepayment.validation.payment_method),
    description: useValidation(Prepayment.validation.description),
    email: useValidation(Prepayment.validation.email),
  };

  const loading = loadingContext.isLoading.length > 0;

  const getPrepayment = () => (
    {
      ...createPrepaymentContext.current,
      name: validation.name.inputRef.current.value,
      price: 5,
      payment_method: "ennakko",
      fee: formatPrice(calculateFee(validation.price.inputRef.current.value), ""),
      description: validation.description.inputRef.current.value,
      email: validation.email.inputRef.current.value,
    });

  const handleBack = () => {
    createPrepaymentContext.modify(getPrepayment());
    navigate("/create-prepayment/create-manually");
  };

  const submit = async (event) => withLoading(loadingContext, async () => {

    event.preventDefault();

    const errors = Object.entries(validation)
      .map(([key, validation]) => ([key, validation.validate()]))
      .filter(([key, error]) => error !== null);

    if (errors.length > 0) {
      const error = errors.map(([key, error]) => `- ${labels[key] ?? "N/A"}: ${error}`).join("\n");
      apiErrorContext.addError(`Tiedoissa on virheitä:\n\n${error}`);
      return;
    }

    await createPrepaymentContext.modifyAndCommit(getPrepayment());

    if (window.dataLayer) {
      window.dataLayer.push({
        event: "create_prepayment",
      });
    }

    navigate("/transactions");
  });


  const handleFee = (event) => {
    const price = event.target.value;
    const fee = calculateFee(price);
    if (fee > 0) {
      document.getElementById("fee").value = formatPrice(fee, "");
    }
  };

  useEffect(() => {
    if (prepaymentId === null) {
      navigate("/create-prepayment");
    }
  }, [prepaymentId, navigate]);


  return (
    <form onSubmit={submit} style={{ height: "fit-content" }} noValidate>
      <VerticalContentGrid>
        <Grid
          item
          container
          justifyContent="center"
          alignItems="center"
          gap={2}
        >
          <AddSharpIcon fontSize="large" />
          <Typography variant="h4">Luo ennakomaksu</Typography>
        </Grid>
        <Grid item container direction="column">
          <InputTextField
            required
            autoComplete="off"
            label={labels.name}
            autoFocus
            defaultValue={createPrepaymentContext.current.name}
            {...validation.name}
          />
          <Grid item container spacing={2} flexWrap={"nowrap"}>
            <Grid item>
              <InputTextField
                required
                label={labels.price}
                style={{}}
                defaultValue={createPrepaymentContext.current.price}
                endAdornment="€"
                onChange={handleFee}
                {...validation.price}
              />
            </Grid>
            <Grid item>
              <InputTextField
                disabled
                label={labels.fee}
                id="fee"
                defaultValue={createPrepaymentContext.current.fee}
                endAdornment="€"
              />
            </Grid>
          </Grid>
          <InputTextField
            label={labels.description}
            multiline
            defaultValue={createPrepaymentContext.current.description}
            {...validation.description}
          />
          <InputTextField
            required
            autoComplete="off"
            label={labels.email}
            type="email"
            defaultValue={createPrepaymentContext.current.email}
            {...validation.email}
          />
        </Grid>
        <Grid item container justifyContent="space-between">
          <Button variant="outlined" onClick={handleBack}>
            Mene takaisin
          </Button>
          <Button variant="contained" type="submit" disabled={loading}>
            Luo kauppa
          </Button>
        </Grid>
      </VerticalContentGrid>
    </form>
  );
}
